<template>
  <div class="w-full">
    <div class="w-full">
      <Card class="p-2 pt-0 mb-5">
        <CardFooter reloadcard />
      </Card>
    </div>
    <div class="">
      <Table
        :headers="headers"
        :items="items"
        :loading="isLoading"
        v-if="items.length > 0"
        style="width: 100%"
        :has-number="false"
        :pagination-list="paginationList"
        page-sync
      >
        <template v-slot:item="{ item }">
          <div
            v-if="item.appraisee"
            class="flex justify-start items-start gap-2"
          >
            <div>
              <div class="w-8 h-8" v-if="item.photo">
                <img class="w-8 h-8 rounded-md" :src="item.data.photo" />
              </div>
              <div
                v-else
                class="text-blueCrayola flex justify-center items-center border rounded-sm w-8 h-8"
              >
                {{ $getInitials(`${item.data.appraisee.name}`) }}
              </div>
            </div>
            <div
              v-if="item.data.appraisee"
              class="flex flex-col whitespace-nowrap font-semibold text-sm leading-5"
            >
              <span
                class="text-darkPurple capitalize"
                v-if="item.data.appraisee"
              >
                {{ item.data.appraisee.name }}
              </span>
              <span
                class="text-carrotOrange uppercase text-xs"
                v-if="item.data.appraisee.position"
              >
                {{ item.data.appraisee.position }}
              </span>
            </div>
          </div>
          <div
            v-if="item.functions"
            class="flex flex-col whitespace-nowrap font-semibold text-sm leading-5"
          >
            <span class="text-darkPurple capitalize">{{
              item.data.functions.department
            }}</span>
            <span class="text-optima text-xs uppercase">{{
              item.data.functions.designation
            }}</span>
          </div>
          <div v-if="item.fnModScore">
            <span
              class="text-center text-sm leading-6 font-normal text-darkPurple"
            >
              {{ item.data.fnModScore }}
            </span>
          </div>
          <div v-if="item.ceoModScore">
            <span
              class="text-center text-sm leading-6 font-normal text-darkPurple"
            >
              {{ item.data.ceoModScore }}
            </span>
          </div>
          <div
            v-if="item.difference"
            class="flex justify-start items-center gap-2"
          >
            <span
              class="text-center text-sm leading-6 font-normal text-darkPurple"
            >
              {{ item.data.difference.score }}
            </span>
            <div
              class="py-1 px-2 rounded-md flex gap-1"
              :class="{
                'bg-red-200 text-desire':
                  item.data.difference.status === 'decreasing',
                'bg-green-200 text-mediumSeaGreen':
                  item.data.difference.status === 'increasing',
                'bg-gray-200 text-romanSilver':
                  item.data.difference.status === 'default',
              }"
            >
              <Icon
                class-name="text-mediumSeaGreen"
                size="xs"
                :icon-name="
                  item.data.difference.status === 'increasing'
                    ? 'icon-trending-up-green'
                    : item.data.difference.status === 'decreasing'
                    ? 'icon-trending-down'
                    : 'icon-dashed'
                "
              />
              <span>{{ item.data.difference.value }}</span>
            </div>
          </div>
          <div v-if="item.performanceBand">
            <span
              class="text-sm leading-6 font-normal text-darkPurple whitespace-nowrap"
            >
              {{ item.data.performanceBand }}
            </span>
          </div>
        </template>
      </Table>

        <div
          class="flex flex-col justify-center items-center gap-5 px-10 py-10 mt-10"
          v-else
        >
          <icon icon-name="empty_agreement" size="l" style="width: 300px" />
          <p class="font-normal text-base leading-5 text-jet text-center w-96">
            Moderation Data not available.
          </p>
        </div>

    </div>
  </div>
</template>

<script>
import Table from "@scelloo/cloudenly-ui/src/components/table";
import Card from "@scelloo/cloudenly-ui/src/components/card";
import Icon from "@/components/Icon";
import CardFooter from "@/components/CardFooter";

export default {
  name: "TFunctionalModeration",
  components: {
    Table,
    Card,
    Icon,
    CardFooter,
  },
  props: {
    isLoading: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      headers: [
        { title: "Appraisee", value: "appraisee" },
        { title: "Function", value: "functions" },
        { title: "Functional Mod. Score", value: "fnModScore" },
        { title: "CEO Moderated  Score", value: "ceoModScore" },
        { title: "Difference", value: "difference" },
        { title: "Performance Band", value: "performanceBand" },
      ],
      items: [],
      paginationList: {
        page: 1,
        lastPage: 1,
        total: 1,
        from: 1,
        to: 1,
      },
    };
  },
  methods: {
    percentageDifference(a, b) {
      return Math.round(100 * Math.abs((a - b) / ((a + b) / 2)) * 100) / 100;
    },
    differenceStatus(a, b) {
      if (a > b) return 1;
      if (a < b) return -1;
      return 0;
    },
    timestamp(date) {
      const formatted = new Date(date.split("/").reverse().join("/"));
      return this.$DATEFORMAT(new Date(formatted), "MMMM dd,");
    },

    ceoModeration() {
      this.$_getCEOModeration(this.$route.params.year).then((response) => {
        const moderationArr = response.data.moderations;

        moderationArr.forEach((data) => {
          this.items.push({
            appraisee: {
              name: `${data.user.fname} ${data.user.lname}`,
              position: "",
              userId: data.user.userId,
            },
            functions: {
              department: `${data.user.function}`,
              designation: `${data.user.designation}`,
            },
            fnModScore: `${data.moderation.averageModerationScore}`,
            ceoModScore: `${
              !data.moderation.ceoDone
                ? "---"
                : data.moderation.ceoModerationScore
            }`,
            difference: {
              score: `${
                !data.moderation.ceoDone ? "---" : data.moderation.difference
              }`,
              status: `${
                data.moderation.difference > 0 ? "increasing" : "decreasing"
              }`,
              value: `${!data.moderation.ceoDone ? "" : data.ceoDifference}`,
            },
            performanceBand: `${
              data.moderation.ceoDone
                ? data.ceofunctionalPerformanceBand
                : "---"
            }`,
            photo: `${data.user.photo}`,
            id: `${data.id}`,
          });
        });
      });
    },
  },

  mounted() {
    this.ceoModeration();
  }
};
</script>